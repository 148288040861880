@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

@layer base {
  body {
    min-height: 100dvh;
    @apply text-base font-fira text-slate-800;

    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }
}

@layer components {
  /* .nav-link {
    @apply min-w-[200px] text-gray-vlg-400 flex items-center gap-2 p-2 px-5 py-3 hover:text-gray-vlg-400 hover:font-semibold hover:bg-gray-vlg-100 hover:scale-[103%] hover:transition-all active:scale-100;
  }

  .nav-link--active {
    @apply bg-gray-vlg-100 font-semibold;
  } */

  .btn-light {
    @apply hover:text-blue-600 gap-2 flex items-center justify-center shadow-sm w-full bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium px-5 py-2.5;
  }

  .btn-light-alt {
    @apply hover:bg-primary gap-2 flex items-center justify-center shadow-sm w-full bg-white border border-gray-400 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-semibold px-5 py-2.5;
  }

  .btn-light-no-border {
    @apply hover:bg-primary gap-2 flex items-center justify-center w-full bg-white  focus:outline-none hover:bg-gray-100 font-medium  px-5 py-2.5   dark:bg-gray-800 dark:text-white  dark:hover:bg-gray-700;
  }

  .btn-primary {
    @apply w-full text-white bg-primary hover:bg-primary/80 focus:ring-4 focus:outline-none focus:ring-primary/20 font-bold  px-5 py-2.5 text-center flex items-center justify-center gap-2;
  }

  .btn-accent {
    @apply w-full text-white bg-accent1 hover:bg-accent1/80 focus:ring-4 focus:outline-none focus:ring-accent1/20 font-bold  px-5 py-2.5 text-center flex items-center justify-center gap-2;
  }

  .checkbox-primary {
    @apply checked:bg-primary-focus w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-2 focus:ring-primary/30;
  }

  .checkbox-label {
    @apply ms-2 text-sm font-medium dark:text-gray-300;
  }

  .input-primary {
    @apply shadow-sm bg-white border text-[15px]  border-gray-300 font-medium  focus:ring-primary  focus:border-primary/80 block w-full p-2.5 placeholder:italic placeholder-gray-400/80;
  }

  .input-error {
    @apply bg-white border text-[15px]  font-medium  border-orange-400 focus:border-orange-400/80 focus:ring-orange-400/40 focus:ring-1 block w-full p-2.5 placeholder:italic placeholder-gray-400/80;
  }

  .input-label {
    @apply block mb-1 text-sm font-bold uppercase;
  }

  .title {
    @apply font-fira font-fira-light text-fira-lg;
  }

  .menu {
    @apply font-fira font-fira-bold text-fira-menu;
  }

  .title-table {
    @apply font-fira font-fira-light text-fira-title-table;
  }

  .first-line-table {
    @apply font-fira font-fira-bold text-fira-bold-table tracking-widest;
  }

  .second-line-table {
    @apply font-fira font-fira-light text-fira-light-table -mt-1.5;
  }
}

@layer utilities {
  .text-stroke {
    -webkit-text-stroke-width: 0.5px; /* Ancho del borde */
    -webkit-text-stroke-color: #000033; /* Color del borde */
    text-stroke-width: 0.5px; /* Ancho del borde para navegadores no basados en WebKit */
    text-stroke-color: #000033; /* Color del borde para navegadores no basados en WebKit */
  }

  .text-shadow {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  }

  .no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}
